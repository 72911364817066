<template>
  <div class="fixedBtn" :style="styleObjectFixBtn">

    <svg class="mob-logo" viewBox="0 0 108 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
            d="M0 12C0 5.37258 5.37258 0 12 0H96C102.627 0 108 5.37258 108 12V49C108 55.6274 102.627 61 96 61H12C5.37258 61 0 55.6274 0 49V12Z"
            fill="white" fill-opacity="0.8"/>
      </g>
      <path
          d="M22.1171 20.6667H22.4839C22.9682 20.6667 23.2176 20.4178 23.2176 19.8889V10.5556C23.2176 10.0422 22.9682 9.77778 22.4839 9.77778H21.2366C20.7377 9.77778 20.4295 10.0267 20.3121 10.5244L19.373 14.3978C19.2996 14.7089 19.2556 15.0044 19.2262 15.2844C19.1969 15.58 19.1675 15.8756 19.1235 16.1711H19.0941C19.0354 15.8756 18.9767 15.58 18.9474 15.2844C18.9034 15.0044 18.8447 14.7089 18.786 14.3978L17.8908 10.54C17.7734 10.0422 17.4653 9.77778 16.981 9.77778H15.7337C15.2348 9.77778 15 10.0422 15 10.5556V19.8889C15 20.4178 15.2348 20.6667 15.7337 20.6667H16.1006C16.5848 20.6667 16.8343 20.4178 16.8343 19.8889V16.6222C16.8343 15.9067 16.8196 15.1911 16.8049 14.4756C16.7903 13.76 16.7756 13.0444 16.7609 12.3289H16.7903C16.8783 12.8267 16.9664 13.3089 17.0544 13.7756C17.1425 14.2578 17.2305 14.74 17.3479 15.2222L18.0816 18.38C18.199 18.8933 18.4925 19.1422 18.9914 19.1422H19.2262C19.7105 19.1422 20.0186 18.8933 20.136 18.38L20.8551 15.2222C20.9725 14.74 21.0605 14.2578 21.1485 13.7756C21.2219 13.3089 21.31 12.8267 21.4274 12.3289H21.4567C21.4274 13.0444 21.4127 13.76 21.398 14.4756C21.3833 15.1911 21.3833 15.9067 21.3833 16.6222V19.8889C21.3833 20.4178 21.6181 20.6667 22.1171 20.6667Z"
          fill="#6D6D6D"/>
      <path
          d="M30.0141 18.1V15.4556C30.0141 15.0978 29.9554 14.7556 29.838 14.4133C29.7206 14.0867 29.5446 13.7911 29.3098 13.5422C29.075 13.2933 28.7815 13.1067 28.4293 12.9511C28.0771 12.8111 27.6809 12.7333 27.226 12.7333C26.7564 12.7333 26.3602 12.8111 26.008 12.9511C25.6559 13.1067 25.377 13.2933 25.1423 13.5422C24.9075 13.7911 24.7314 14.0867 24.614 14.4133C24.4966 14.7556 24.4379 15.0978 24.4379 15.4556V18.1C24.4379 18.4733 24.4966 18.8156 24.614 19.1422C24.7314 19.4844 24.9075 19.7644 25.1423 20.0133C25.377 20.2622 25.6559 20.4644 26.008 20.6044C26.3602 20.76 26.7564 20.8222 27.226 20.8222C27.6809 20.8222 28.0771 20.76 28.4293 20.6044C28.7815 20.4644 29.075 20.2622 29.3098 20.0133C29.5446 19.7644 29.7206 19.4844 29.838 19.1422C29.9554 18.8156 30.0141 18.4733 30.0141 18.1ZM28.2532 15.4556V18.1C28.2532 18.38 28.1652 18.5978 27.9891 18.7844C27.813 18.9711 27.5635 19.0644 27.226 19.0644C26.8738 19.0644 26.6244 18.9711 26.4483 18.7844C26.2722 18.5978 26.1988 18.38 26.1988 18.1V15.4556C26.1988 15.2067 26.2722 14.9733 26.4483 14.7867C26.6244 14.6 26.8738 14.5067 27.226 14.5067C27.5635 14.5067 27.813 14.6 27.9891 14.7867C28.1652 14.9733 28.2532 15.2067 28.2532 15.4556Z"
          fill="#6D6D6D"/>
      <path
          d="M30.664 12.08H31.5152C31.7059 12.08 31.8086 11.9867 31.8086 11.7689V10.9911C31.8086 10.7889 31.7059 10.68 31.5152 10.68H30.664C30.4586 10.68 30.3706 10.7889 30.3706 10.9911V11.7689C30.3706 11.9867 30.4586 12.08 30.664 12.08ZM32.9092 12.08H33.7457C33.9364 12.08 34.0391 11.9867 34.0391 11.7689V10.9911C34.0391 10.7889 33.9364 10.68 33.7457 10.68H32.9092C32.7038 10.68 32.6157 10.7889 32.6157 10.9911V11.7689C32.6157 11.9867 32.7038 12.08 32.9092 12.08ZM31.8967 20.6667H32.4837C32.8652 20.6667 33.0706 20.4644 33.0706 20.0444V13.5111C33.0706 13.1067 32.8652 12.8889 32.4837 12.8889H31.8967C31.5005 12.8889 31.3097 13.1067 31.3097 13.5111V20.0444C31.3097 20.4644 31.5005 20.6667 31.8967 20.6667Z"
          fill="#6D6D6D"/>
      <path
          d="M46.3069 22.3778H46.6884C47.0699 22.3778 47.2754 22.1756 47.2754 21.7556V19.5311C47.2754 19.1578 47.0993 18.94 46.7765 18.9089C46.6004 18.9089 46.527 18.8 46.527 18.5978V13.5111C46.527 13.1067 46.3216 12.8889 45.94 12.8889H45.3531C44.9568 12.8889 44.7661 13.1067 44.7661 13.5111V18.5978C44.7661 18.8156 44.6634 18.9089 44.4726 18.9089H43.6655C43.4601 18.9089 43.372 18.8156 43.372 18.5978V13.5111C43.372 13.1067 43.1666 12.8889 42.785 12.8889H42.1981C41.8019 12.8889 41.6111 13.1067 41.6111 13.5111V18.5978C41.6111 18.8156 41.5084 18.9089 41.3176 18.9089H40.5105C40.3051 18.9089 40.217 18.8156 40.217 18.5978V13.5111C40.217 13.1067 40.0116 12.8889 39.6301 12.8889H39.0431C38.6469 12.8889 38.4561 13.1067 38.4561 13.5111V20.0444C38.4561 20.4644 38.6469 20.6667 39.0431 20.6667H45.4264C45.6172 20.6667 45.7199 20.7756 45.7199 20.9778V21.7556C45.7199 22.1756 45.9107 22.3778 46.3069 22.3778Z"
          fill="#6D6D6D"/>
      <path
          d="M52.7379 18.1H52.1509C51.8721 18.1 51.7107 18.1622 51.652 18.2556C51.5933 18.3489 51.564 18.4578 51.564 18.5667C51.564 18.6289 51.5493 18.7067 51.5346 18.7689C51.5053 18.8467 51.4759 18.9089 51.4172 18.9711C51.3585 19.0333 51.2705 19.08 51.1531 19.1111C51.0357 19.1422 50.8889 19.1578 50.6982 19.1422C50.5514 19.1422 50.4047 19.1111 50.2873 19.08C50.1699 19.0489 50.0672 18.9711 49.9791 18.8778C49.8911 18.7844 49.8177 18.66 49.7737 18.4733C49.7297 18.3022 49.715 18.0689 49.715 17.7733V17.54C49.715 17.3689 49.803 17.2756 50.0085 17.2756H52.7966C53.1781 17.2756 53.3836 17.0733 53.3836 16.6533V15.1444C53.3836 14.8333 53.3249 14.5222 53.2075 14.2267C53.0901 13.9311 52.914 13.6667 52.6792 13.4489C52.4444 13.2311 52.1656 13.06 51.8428 12.9356C51.5053 12.8111 51.1237 12.7333 50.6982 12.7333C50.2286 12.7333 49.8324 12.8111 49.4949 12.9667C49.1427 13.1222 48.8639 13.3244 48.6291 13.5733C48.3943 13.8222 48.2329 14.1178 48.1155 14.46C47.9981 14.8022 47.9541 15.16 47.9541 15.5333V18.0222C47.9541 18.3956 48.0128 18.7533 48.1302 19.0956C48.2476 19.4378 48.409 19.7489 48.6438 19.9978C48.8639 20.2467 49.1427 20.4489 49.4949 20.6044C49.8324 20.76 50.2286 20.8222 50.6835 20.8222C51.0504 20.8222 51.4025 20.7756 51.7254 20.6667C52.0482 20.5733 52.327 20.4178 52.5618 20.2311C52.7966 20.0444 52.9727 19.8267 53.1194 19.5778C53.2515 19.3289 53.3249 19.0489 53.3249 18.7378C53.3249 18.52 53.2809 18.3489 53.1928 18.2556C53.1048 18.1622 52.9433 18.1 52.7379 18.1ZM51.6227 15.3V15.5333C51.6227 15.7511 51.5199 15.8444 51.3292 15.8444H50.0085C49.803 15.8444 49.715 15.7511 49.715 15.5333V15.3C49.715 15.0667 49.7884 14.8644 49.9645 14.6933C50.1406 14.5222 50.3753 14.4289 50.6982 14.4289C50.9917 14.4289 51.2118 14.5222 51.3732 14.6933C51.5346 14.8644 51.6227 15.0667 51.6227 15.3Z"
          fill="#6D6D6D"/>
      <path
          d="M58.6904 20.6667H59.2774C59.6589 20.6667 59.8644 20.4644 59.8644 20.0444V13.5111C59.8644 13.1067 59.6589 12.8889 59.2774 12.8889H55.0218C54.6256 12.8889 54.4349 13.1067 54.4349 13.5111V20.0444C54.4349 20.4644 54.6256 20.6667 55.0218 20.6667H55.6088C55.9903 20.6667 56.1958 20.4644 56.1958 20.0444V14.9733C56.1958 14.7711 56.2838 14.6622 56.4893 14.6622H57.81C58.0007 14.6622 58.1034 14.7711 58.1034 14.9733V20.0444C58.1034 20.4644 58.2942 20.6667 58.6904 20.6667Z"
          fill="#6D6D6D"/>
      <path
          d="M64.9282 20.6667H65.5151C65.8967 20.6667 66.1021 20.4644 66.1021 20.0444V13.5111C66.1021 13.1067 65.8967 12.8889 65.5151 12.8889H62.4629C62.0373 12.8889 61.8319 13.0911 61.8319 13.4956C61.8025 14.46 61.7732 15.2689 61.7291 15.9378C61.6851 16.6222 61.6264 17.1667 61.5531 17.6022C61.4797 18.0378 61.3916 18.3644 61.2889 18.5667C61.1862 18.7844 61.0688 18.8933 60.9514 18.9089C60.6286 18.9556 60.4818 19.1578 60.4818 19.5311V20.0444C60.4818 20.4489 60.6286 20.6667 60.9514 20.6667H61.0688C61.509 20.6511 61.8759 20.5267 62.1694 20.2778C62.4629 20.0444 62.6977 19.6867 62.8737 19.22C63.0498 18.7533 63.1672 18.1778 63.2553 17.4622C63.3433 16.7467 63.402 15.9067 63.446 14.9422C63.446 14.8178 63.4607 14.74 63.5194 14.7089C63.5781 14.6778 63.6515 14.6622 63.7689 14.6622H64.0477C64.2385 14.6622 64.3412 14.7711 64.3412 14.9733V20.0444C64.3412 20.4644 64.5319 20.6667 64.9282 20.6667Z"
          fill="#6D6D6D"/>
      <path
          d="M71.9836 18.1H71.3967C71.1179 18.1 70.9564 18.1622 70.8977 18.2556C70.839 18.3489 70.8097 18.4578 70.8097 18.5667C70.8097 18.6289 70.795 18.7067 70.7803 18.7689C70.751 18.8467 70.7216 18.9089 70.663 18.9711C70.6043 19.0333 70.5162 19.08 70.3988 19.1111C70.2814 19.1422 70.1347 19.1578 69.9439 19.1422C69.7972 19.1422 69.6504 19.1111 69.533 19.08C69.4156 19.0489 69.3129 18.9711 69.2249 18.8778C69.1368 18.7844 69.0634 18.66 69.0194 18.4733C68.9754 18.3022 68.9607 18.0689 68.9607 17.7733V17.54C68.9607 17.3689 69.0488 17.2756 69.2542 17.2756H72.0423C72.4239 17.2756 72.6293 17.0733 72.6293 16.6533V15.1444C72.6293 14.8333 72.5706 14.5222 72.4532 14.2267C72.3358 13.9311 72.1597 13.6667 71.9249 13.4489C71.6902 13.2311 71.4113 13.06 71.0885 12.9356C70.751 12.8111 70.3695 12.7333 69.9439 12.7333C69.4743 12.7333 69.0781 12.8111 68.7406 12.9667C68.3884 13.1222 68.1096 13.3244 67.8748 13.5733C67.64 13.8222 67.4786 14.1178 67.3612 14.46C67.2438 14.8022 67.1998 15.16 67.1998 15.5333V18.0222C67.1998 18.3956 67.2585 18.7533 67.3759 19.0956C67.4933 19.4378 67.6547 19.7489 67.8895 19.9978C68.1096 20.2467 68.3884 20.4489 68.7406 20.6044C69.0781 20.76 69.4743 20.8222 69.9292 20.8222C70.2961 20.8222 70.6483 20.7756 70.9711 20.6667C71.2939 20.5733 71.5728 20.4178 71.8075 20.2311C72.0423 20.0444 72.2184 19.8267 72.3652 19.5778C72.4972 19.3289 72.5706 19.0489 72.5706 18.7378C72.5706 18.52 72.5266 18.3489 72.4385 18.2556C72.3505 18.1622 72.1891 18.1 71.9836 18.1ZM70.8684 15.3V15.5333C70.8684 15.7511 70.7657 15.8444 70.5749 15.8444H69.2542C69.0488 15.8444 68.9607 15.7511 68.9607 15.5333V15.3C68.9607 15.0667 69.0341 14.8644 69.2102 14.6933C69.3863 14.5222 69.6211 14.4289 69.9439 14.4289C70.2374 14.4289 70.4575 14.5222 70.6189 14.6933C70.7803 14.8644 70.8684 15.0667 70.8684 15.3Z"
          fill="#6D6D6D"/>
      <path
          d="M77.9361 20.6667H78.5231C78.9047 20.6667 79.1101 20.4644 79.1101 20.0444V13.5111C79.1101 13.1067 78.9047 12.8889 78.5231 12.8889H77.9361C77.5399 12.8889 77.3492 13.1067 77.3492 13.5111V15.5956C77.3492 15.8133 77.2465 15.9067 77.0557 15.9067H75.735C75.5296 15.9067 75.4415 15.8133 75.4415 15.5956V13.5111C75.4415 13.1067 75.2361 12.8889 74.8545 12.8889H74.2676C73.8714 12.8889 73.6806 13.1067 73.6806 13.5111V20.0444C73.6806 20.4644 73.8714 20.6667 74.2676 20.6667H74.8545C75.2361 20.6667 75.4415 20.4644 75.4415 20.0444V17.9756C75.4415 17.7733 75.5296 17.6644 75.735 17.6644H77.0557C77.2465 17.6644 77.3492 17.7733 77.3492 17.9756V20.0444C77.3492 20.4644 77.5399 20.6667 77.9361 20.6667Z"
          fill="#6D6D6D"/>
      <path
          d="M84.6141 20.6667H85.2011C85.5826 20.6667 85.7881 20.4644 85.7881 20.0444V13.5111C85.7881 13.1067 85.5826 12.8889 85.2011 12.8889H84.6141C84.2179 12.8889 84.0271 13.1067 84.0271 13.5111V15.5956C84.0271 15.8133 83.9244 15.9067 83.7337 15.9067H82.413C82.2075 15.9067 82.1195 15.8133 82.1195 15.5956V13.5111C82.1195 13.1067 81.914 12.8889 81.5325 12.8889H80.9455C80.5493 12.8889 80.3586 13.1067 80.3586 13.5111V20.0444C80.3586 20.4644 80.5493 20.6667 80.9455 20.6667H81.5325C81.914 20.6667 82.1195 20.4644 82.1195 20.0444V17.9756C82.1195 17.7733 82.2075 17.6644 82.413 17.6644H83.7337C83.9244 17.6644 84.0271 17.7733 84.0271 17.9756V20.0444C84.0271 20.4644 84.2179 20.6667 84.6141 20.6667Z"
          fill="#6D6D6D"/>
      <path
          d="M90.8078 20.6667H91.3948C91.7763 20.6667 91.9818 20.4644 91.9818 20.0444V13.5111C91.9818 13.1067 91.7763 12.8889 91.3948 12.8889H89.3697C88.548 12.8889 87.8876 13.1222 87.4181 13.5578C86.9338 13.9933 86.699 14.6 86.699 15.3467C86.699 16 86.8164 16.4978 87.0806 16.8711C87.3447 17.2444 87.6969 17.5089 88.1371 17.68L86.8164 20.1222C86.611 20.4956 86.7137 20.6667 87.1099 20.6667H87.7409C88.1224 20.6667 88.4159 20.4956 88.6214 20.1222L89.854 17.5556C89.942 17.3533 90.0007 17.1822 90.0154 17.0267C90.0154 16.8711 90.0007 16.7467 89.942 16.6378C89.8833 16.5444 89.7953 16.4667 89.6779 16.42C89.5605 16.3733 89.4431 16.3422 89.3257 16.3422C89.0029 16.3422 88.7681 16.2644 88.6507 16.1244C88.5186 16 88.4599 15.7667 88.4599 15.4244C88.4599 15.1133 88.504 14.8956 88.6067 14.7556C88.6947 14.6156 88.9442 14.5378 89.3257 14.5378H89.9274C90.1181 14.5378 90.2208 14.6467 90.2208 14.8489V20.0444C90.2208 20.4644 90.4116 20.6667 90.8078 20.6667Z"
          fill="#6D6D6D"/>
      <path
          d="M93.0658 23H93.7115C94.093 23 94.3572 22.8133 94.5039 22.4089L98.9502 9.59111C99.0823 9.20222 98.9502 9 98.5687 9H97.923C97.5268 9 97.2627 9.20222 97.1306 9.59111L92.6843 22.4089C92.5376 22.8133 92.6696 23 93.0658 23Z"
          fill="#6D6D6D"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M21 29C17.6863 29 15 31.6863 15 35V44.3063C15 47.6201 17.6863 50.3063 21 50.3063H37.9453L41.7453 54.1064C42.1358 54.4969 42.769 54.4969 43.1595 54.1064L46.9595 50.3063H63.9048C67.2185 50.3063 69.9048 47.6201 69.9048 44.3063V35C69.9048 31.6863 67.2185 29 63.9048 29H21Z"
            fill="#F36633"/>
      <path
          d="M22.6377 43.7505L25.7058 34.3102H27.6791L30.7472 43.7505H29.3246L26.4991 35.1362H26.8531L24.0603 43.7505H22.6377ZM24.2308 41.6199V40.335H29.1607V41.6199H24.2308Z"
          fill="white"/>
      <path d="M33.7524 43.7505V37.9028H31.3333V36.6703H37.5417V37.9028H35.1226V43.7505H33.7524Z" fill="white"/>
      <path
          d="M38.0028 43.7505V42.5902C38.2651 42.6382 38.4639 42.6317 38.5994 42.5705C38.7393 42.5049 38.842 42.3869 38.9075 42.2165C38.9775 42.046 39.0365 41.8253 39.0846 41.5543C39.1589 41.1566 39.2222 40.7043 39.2747 40.1973C39.3315 39.6859 39.3818 39.1331 39.4255 38.5387C39.4692 37.9443 39.5085 37.3215 39.5435 36.6703H44.6308V43.7505H43.254V37.9618H40.776C40.7497 38.307 40.7191 38.6742 40.6842 39.0631C40.6536 39.4477 40.6186 39.8302 40.5793 40.2104C40.5399 40.5906 40.4984 40.9468 40.4547 41.279C40.411 41.6112 40.3651 41.8952 40.317 42.1312C40.2296 42.6033 40.1007 42.9791 39.9303 43.2588C39.7642 43.5342 39.5282 43.7112 39.2222 43.7899C38.9163 43.8729 38.5098 43.8598 38.0028 43.7505Z"
          fill="white"/>
      <path
          d="M48.4686 43.9472C47.9441 43.9472 47.5049 43.8511 47.1509 43.6587C46.7968 43.4621 46.5281 43.2042 46.3445 42.8852C46.1653 42.5617 46.0757 42.2077 46.0757 41.8231C46.0757 41.4647 46.1391 41.1501 46.2658 40.8791C46.3926 40.6081 46.5805 40.3787 46.8296 40.1907C47.0787 39.9984 47.3847 39.8433 47.7474 39.7253C48.0621 39.6335 48.4183 39.5526 48.816 39.4827C49.2137 39.4128 49.6311 39.3472 50.0682 39.286C50.5096 39.2248 50.9467 39.1637 51.3793 39.1025L50.8811 39.3778C50.8898 38.8228 50.7718 38.4119 50.5271 38.1453C50.2867 37.8744 49.8715 37.7389 49.2815 37.7389C48.91 37.7389 48.5691 37.8263 48.2588 38.0011C47.9485 38.1715 47.7321 38.4556 47.6098 38.8533L46.3314 38.46C46.5062 37.8525 46.8384 37.3696 47.3279 37.0112C47.8217 36.6528 48.4773 36.4736 49.2946 36.4736C49.9283 36.4736 50.479 36.5829 50.9467 36.8014C51.4187 37.0155 51.7639 37.3564 51.9825 37.8241C52.0961 38.0557 52.166 38.3005 52.1923 38.5583C52.2185 38.8162 52.2316 39.0937 52.2316 39.3909V43.7505H51.0188V42.1312L51.2548 42.341C50.962 42.883 50.5883 43.2873 50.1337 43.5539C49.6836 43.8161 49.1285 43.9472 48.4686 43.9472ZM48.7111 42.8262C49.1001 42.8262 49.4345 42.7584 49.7142 42.6229C49.9939 42.4831 50.219 42.3061 50.3894 42.0919C50.5599 41.8778 50.6713 41.6549 50.7238 41.4232C50.7981 41.2134 50.8396 40.9774 50.8483 40.7152C50.8614 40.453 50.868 40.2432 50.868 40.0858L51.3138 40.2497C50.8811 40.3153 50.4878 40.3743 50.1337 40.4267C49.7797 40.4792 49.4585 40.5316 49.17 40.5841C48.886 40.6322 48.6325 40.6912 48.4096 40.7611C48.2216 40.8266 48.0534 40.9053 47.9048 40.9971C47.7605 41.0889 47.6447 41.2003 47.5573 41.3314C47.4743 41.4626 47.4328 41.6221 47.4328 41.81C47.4328 41.9936 47.4786 42.164 47.5704 42.3214C47.6622 42.4743 47.8021 42.5967 47.99 42.6885C48.1779 42.7803 48.4183 42.8262 48.7111 42.8262Z"
          fill="white"/>
      <path
          d="M56.9627 43.9472C56.2372 43.9472 55.621 43.7855 55.114 43.4621C54.607 43.1387 54.218 42.695 53.9471 42.1312C53.6805 41.5675 53.545 40.9272 53.5406 40.2104C53.545 39.4805 53.6848 38.8359 53.9602 38.2764C54.2355 37.7126 54.6289 37.2712 55.1402 36.9522C55.6516 36.6331 56.2656 36.4736 56.9824 36.4736C57.756 36.4736 58.4159 36.6659 58.9622 37.0505C59.5129 37.4351 59.8757 37.9618 60.0505 38.6305L58.6869 39.0238C58.5514 38.6261 58.3285 38.318 58.0182 38.0994C57.7123 37.8765 57.3605 37.7651 56.9627 37.7651C56.5126 37.7651 56.1433 37.8722 55.8548 38.0863C55.5663 38.2961 55.3522 38.5846 55.2123 38.9517C55.0725 39.3188 55.0004 39.7384 54.996 40.2104C55.0004 40.9403 55.1664 41.5303 55.4942 41.9805C55.8264 42.4306 56.3159 42.6557 56.9627 42.6557C57.4042 42.6557 57.7604 42.5552 58.0313 42.3541C58.3067 42.1487 58.5165 41.8559 58.6607 41.4757L60.0505 41.8035C59.8189 42.494 59.4365 43.025 58.9033 43.3965C58.37 43.7636 57.7232 43.9472 56.9627 43.9472Z"
          fill="white"/>
    </svg>


    <div
        @click="scrollMeTo('.section3');"
        onclick="gtag('event', 'click', {'event_category': 'Підготуватися до подорожі', 'event_label': 'Головна сторінка'})"
        class="btn btn1">
      <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_374_2173)">
          <path
              d="M3.83 15.78C3.97 15.64 4.05 15.45 4.04 15.27V12.08C4.04 11.78 4.11 11.56 4.25 11.42C4.39 11.28 4.6 11.21 4.9 11.21H7.7C8.01 11.21 8.22 11.28 8.36 11.42C8.5 11.56 8.57 11.78 8.57 12.08V15.27C8.57 15.45 8.65 15.64 8.79 15.78C8.93 15.92 9.11 16 9.3 16C9.49 16 9.67 15.92 9.81 15.78C9.95 15.64 10.03 15.45 10.02 15.27V12.08C10.02 11.4 9.79 10.8 9.39 10.4C8.98 9.99002 8.39 9.77002 7.71 9.77002H4.91C4.24 9.77002 3.64 10 3.23 10.4C2.82 10.81 2.6 11.4 2.6 12.08V15.27C2.6 15.45 2.68 15.64 2.81 15.78C3.09 16.06 3.55 16.06 3.83 15.78Z"
              fill="#13575B"/>
          <path
              d="M10.3 4.18H9.03V0.92C9.03 0.53 8.69 0.2 8.31 0.2H7.62C7.49 0.07 7.31 0 7.12 0H5.45C5.28 0.01 5.12 0.08 4.99 0.2H4.24C3.89 0.24 3.59 0.57 3.59 0.92V4.18H2.32C1.04 4.18 0 5.23 0 6.52V16.82C0 18.11 1.04 19.17 2.32 19.17H3.41C3.44 19.31 3.51 19.45 3.61 19.55C3.89 19.83 4.35 19.83 4.63 19.55C4.74 19.44 4.81 19.31 4.83 19.17H7.79C7.82 19.31 7.89 19.45 8 19.55C8.14 19.69 8.32 19.77 8.51 19.77C8.7 19.77 8.88 19.69 9.02 19.55C9.13 19.44 9.2 19.31 9.23 19.17H10.31C11.59 19.17 12.63 18.12 12.63 16.82V6.53C12.63 5.24 11.59 4.19 10.31 4.19L10.3 4.18ZM5.04 4.18V1.7C5.18 1.8 5.35 1.86 5.51 1.85H7.11C7.28 1.85 7.45 1.79 7.57 1.68V4.19H5.03L5.04 4.18ZM1.45 6.53C1.45 6.02 1.82 5.63 2.32 5.63H10.3C10.8 5.63 11.17 6.02 11.17 6.53V16.83C11.17 17.34 10.79 17.73 10.3 17.73H2.32C1.83 17.73 1.45 17.33 1.45 16.83V6.53Z"
              fill="#13575B"/>
          <path
              d="M5.48 8.81999H7.11C7.3 8.81999 7.48 8.74 7.62 8.6C7.76 8.46 7.84 8.28 7.84 8.09C7.84 7.9 7.76 7.72 7.62 7.58C7.48 7.44 7.3 7.37 7.12 7.37H5.45C5.27 7.37 5.09 7.47 4.96 7.61C4.83 7.76 4.76 7.94 4.77 8.13C4.79 8.5 5.12 8.81999 5.49 8.81999H5.48Z"
              fill="#13575B"/>
        </g>
        <defs>
          <clipPath id="clip0_374_2173">
            <rect width="12.62" height="19.78" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <p>Підготуватися до подорожі</p>
    </div>
    <div @click="menu = !menu; getHeight()" class="btn btn2">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.86691 4.21997H17.0796" stroke="#13575B" stroke-width="2" stroke-linecap="round"/>
        <path d="M2.86691 10H17.0796" stroke="#13575B" stroke-width="2" stroke-linecap="round"/>
        <path d="M2.86691 15.78H17.0796" stroke="#13575B" stroke-width="2" stroke-linecap="round"/>
      </svg>
      <p>Меню</p>
    </div>


    <div class="menu-block" v-bind:class="[{ active: menu }]">
      <div class="white-bg">

        <div class="menu-content-wrapper">
          <div class="menu-title">Меню</div>

          <div @click="menu = !menu; getHeight()" class="close-btn">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="24" cy="24" r="23.5" transform="rotate(90 24 24)" fill="white" stroke="#13575B"/>
              <line x1="29.4541" y1="18.7472" x2="18.7465" y2="29.4548" stroke="#13575B" stroke-width="2"
                    stroke-linecap="round"/>
              <line x1="18.7462" y1="18.5452" x2="29.4539" y2="29.2528" stroke="#13575B" stroke-width="2"
                    stroke-linecap="round"/>
            </svg>
          </div>

          <div class="line1">
            <div onclick="gtag('event', 'click', {'event_category': 'Атлас щеплень мандрівника', 'event_label': 'Головна сторінка'})"
                @click="scrollMeTo('.map-wrapper'); menu = false; "
                class="link1">Атлас щеплень мандрівника
            </div>
            <div
                @click="scrollMeTo('.section3'); menu = false; "
                onclick="gtag('event', 'click', {'event_category': '5 правил подорожуючого', 'event_label': 'Головна сторінка'})"
                class="link2">5 правил подорожуючого
              <svg class="arrow" width="20" height="19" viewBox="0 0 20 19" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5 9.49998L0 9.5" stroke="#F36633" stroke-width="2"/>
                <path d="M10.6431 18.0714L18.5 9.5L10 1" stroke="#F36633" stroke-width="2"/>
              </svg>
            </div>
          </div>

          <div class="line2">
            <div class="sep">
              <svg width="143" height="23" viewBox="0 0 143 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.0985 18.709H12.7221C13.5453 18.709 13.9694 18.3099 13.9694 17.4618V2.49454C13.9694 1.67134 13.5453 1.24727 12.7221 1.24727H10.6018C9.75364 1.24727 9.22979 1.6464 9.03023 2.44465L7.43372 8.65605C7.30899 9.15495 7.23416 9.62892 7.18427 10.0779C7.13438 10.5519 7.08449 11.0259 7.00965 11.4998H6.95976C6.85998 11.0259 6.7602 10.5519 6.71031 10.0779C6.63547 9.62892 6.53569 9.15495 6.43591 8.65605L4.91424 2.46959C4.71468 1.67134 4.19082 1.24727 3.36763 1.24727H1.24727C0.399126 1.24727 0 1.67134 0 2.49454V17.4618C0 18.3099 0.399126 18.709 1.24727 18.709H1.8709C2.6941 18.709 3.11817 18.3099 3.11817 17.4618V12.2232C3.11817 11.0757 3.09323 9.92826 3.06828 8.78077C3.04334 7.63328 3.01839 6.4858 2.99344 5.33831H3.04334C3.19301 6.13656 3.34268 6.90987 3.49235 7.65823C3.64202 8.43154 3.7917 9.20485 3.99126 9.97815L5.23853 15.0421C5.43809 15.8653 5.937 16.2644 6.78514 16.2644H7.18427C8.00747 16.2644 8.53132 15.8653 8.73088 15.0421L9.95321 9.97815C10.1528 9.20485 10.3024 8.43154 10.4521 7.65823C10.5768 6.90987 10.7265 6.13656 10.9261 5.33831H10.976C10.9261 6.4858 10.9011 7.63328 10.8762 8.78077C10.8512 9.92826 10.8512 11.0757 10.8512 12.2232V17.4618C10.8512 18.3099 11.2504 18.709 12.0985 18.709Z"
                    fill="#6D6D6D"/>
                <path
                    d="M25.523 14.593V10.3523C25.523 9.77859 25.4232 9.22979 25.2237 8.68099C25.0241 8.15714 24.7248 7.68318 24.3256 7.28405C23.9265 6.88492 23.4276 6.58558 22.8289 6.33613C22.2302 6.11162 21.5567 5.98689 20.7834 5.98689C19.9851 5.98689 19.3116 6.11162 18.7129 6.33613C18.1142 6.58558 17.6403 6.88492 17.2411 7.28405C16.842 7.68318 16.5427 8.15714 16.3431 8.68099C16.1436 9.22979 16.0438 9.77859 16.0438 10.3523V14.593C16.0438 15.1917 16.1436 15.7405 16.3431 16.2644C16.5427 16.8132 16.842 17.2622 17.2411 17.6613C17.6403 18.0605 18.1142 18.3847 18.7129 18.6093C19.3116 18.8587 19.9851 18.9585 20.7834 18.9585C21.5567 18.9585 22.2302 18.8587 22.8289 18.6093C23.4276 18.3847 23.9265 18.0605 24.3256 17.6613C24.7248 17.2622 25.0241 16.8132 25.2237 16.2644C25.4232 15.7405 25.523 15.1917 25.523 14.593ZM22.5296 10.3523V14.593C22.5296 15.0421 22.3799 15.3913 22.0806 15.6906C21.7812 15.99 21.3571 16.1397 20.7834 16.1397C20.1847 16.1397 19.7606 15.99 19.4613 15.6906C19.1619 15.3913 19.0372 15.0421 19.0372 14.593V10.3523C19.0372 9.95321 19.1619 9.57903 19.4613 9.27968C19.7606 8.98034 20.1847 8.83066 20.7834 8.83066C21.3571 8.83066 21.7812 8.98034 22.0806 9.27968C22.3799 9.57903 22.5296 9.95321 22.5296 10.3523Z"
                    fill="#6D6D6D"/>
                <path
                    d="M26.6278 4.93918H28.0747C28.3989 4.93918 28.5736 4.78951 28.5736 4.44028V3.19301C28.5736 2.86872 28.3989 2.6941 28.0747 2.6941H26.6278C26.2786 2.6941 26.1289 2.86872 26.1289 3.19301V4.44028C26.1289 4.78951 26.2786 4.93918 26.6278 4.93918ZM30.4445 4.93918H31.8664C32.1906 4.93918 32.3653 4.78951 32.3653 4.44028V3.19301C32.3653 2.86872 32.1906 2.6941 31.8664 2.6941H30.4445C30.0952 2.6941 29.9456 2.86872 29.9456 3.19301V4.44028C29.9456 4.78951 30.0952 4.93918 30.4445 4.93918ZM28.7232 18.709H29.721C30.3696 18.709 30.7189 18.3847 30.7189 17.7112V7.23416C30.7189 6.58558 30.3696 6.23635 29.721 6.23635H28.7232C28.0497 6.23635 27.7254 6.58558 27.7254 7.23416V17.7112C27.7254 18.3847 28.0497 18.709 28.7232 18.709Z"
                    fill="#6D6D6D"/>
                <path
                    d="M53.2196 21.453H53.8682C54.5167 21.453 54.866 21.1287 54.866 20.4552V16.888C54.866 16.2893 54.5666 15.9401 54.0178 15.8902C53.7185 15.8902 53.5938 15.7156 53.5938 15.3913V7.23416C53.5938 6.58558 53.2445 6.23635 52.596 6.23635H51.5981C50.9246 6.23635 50.6003 6.58558 50.6003 7.23416V15.3913C50.6003 15.7405 50.4257 15.8902 50.1014 15.8902H48.7294C48.3802 15.8902 48.2305 15.7405 48.2305 15.3913V7.23416C48.2305 6.58558 47.8813 6.23635 47.2327 6.23635H46.2349C45.5614 6.23635 45.2371 6.58558 45.2371 7.23416V15.3913C45.2371 15.7405 45.0625 15.8902 44.7382 15.8902H43.3662C43.0169 15.8902 42.8673 15.7405 42.8673 15.3913V7.23416C42.8673 6.58558 42.518 6.23635 41.8694 6.23635H40.8716C40.1981 6.23635 39.8738 6.58558 39.8738 7.23416V17.7112C39.8738 18.3847 40.1981 18.709 40.8716 18.709H51.7229C52.0472 18.709 52.2218 18.8837 52.2218 19.2079V20.4552C52.2218 21.1287 52.5461 21.453 53.2196 21.453Z"
                    fill="#6D6D6D"/>
                <path
                    d="M64.1519 14.593H63.1541C62.6801 14.593 62.4057 14.6928 62.3059 14.8425C62.2062 14.9922 62.1563 15.1668 62.1563 15.3414C62.1563 15.4412 62.1313 15.5659 62.1064 15.6657C62.0565 15.7904 62.0066 15.8902 61.9068 15.99C61.807 16.0898 61.6574 16.1646 61.4578 16.2145C61.2582 16.2644 61.0088 16.2893 60.6845 16.2644C60.435 16.2644 60.1856 16.2145 59.986 16.1646C59.7865 16.1147 59.6118 15.99 59.4622 15.8403C59.3125 15.6906 59.1878 15.4911 59.1129 15.1917C59.0381 14.9173 59.0132 14.5432 59.0132 14.0692V13.695C59.0132 13.4206 59.1628 13.2709 59.5121 13.2709H64.2517C64.9003 13.2709 65.2495 12.9467 65.2495 12.2731V9.85343C65.2495 9.35452 65.1497 8.85561 64.9502 8.38165C64.7506 7.90769 64.4512 7.48361 64.0521 7.13438C63.653 6.78514 63.179 6.51074 62.6302 6.31118C62.0565 6.11162 61.4079 5.98689 60.6845 5.98689C59.8862 5.98689 59.2127 6.11162 58.639 6.36107C58.0403 6.61053 57.5663 6.93482 57.1672 7.33394C56.7681 7.73307 56.4937 8.20703 56.2941 8.75583C56.0945 9.30463 56.0197 9.87837 56.0197 10.4771V14.4683C56.0197 15.067 56.1195 15.6408 56.3191 16.1896C56.5186 16.7383 56.793 17.2373 57.1921 17.6364C57.5663 18.0355 58.0403 18.3598 58.639 18.6093C59.2127 18.8587 59.8862 18.9585 60.6595 18.9585C61.2832 18.9585 61.8819 18.8837 62.4307 18.709C62.9795 18.5594 63.4534 18.3099 63.8526 18.0106C64.2517 17.7112 64.551 17.362 64.8005 16.9629C65.025 16.5637 65.1497 16.1147 65.1497 15.6158C65.1497 15.2666 65.0749 14.9922 64.9252 14.8425C64.7755 14.6928 64.5011 14.593 64.1519 14.593ZM62.2561 10.1029V10.4771C62.2561 10.8263 62.0814 10.976 61.7571 10.976H59.5121C59.1628 10.976 59.0132 10.8263 59.0132 10.4771V10.1029C59.0132 9.7287 59.1379 9.40441 59.4372 9.13001C59.7366 8.85561 60.1357 8.70594 60.6845 8.70594C61.1834 8.70594 61.5576 8.85561 61.832 9.13001C62.1064 9.40441 62.2561 9.7287 62.2561 10.1029Z"
                    fill="#6D6D6D"/>
                <path
                    d="M74.2708 18.709H75.2686C75.9172 18.709 76.2664 18.3847 76.2664 17.7112V7.23416C76.2664 6.58558 75.9172 6.23635 75.2686 6.23635H68.0344C67.3609 6.23635 67.0366 6.58558 67.0366 7.23416V17.7112C67.0366 18.3847 67.3609 18.709 68.0344 18.709H69.0322C69.6808 18.709 70.0301 18.3847 70.0301 17.7112V9.57903C70.0301 9.25474 70.1797 9.08012 70.529 9.08012H72.774C73.0983 9.08012 73.2729 9.25474 73.2729 9.57903V17.7112C73.2729 18.3847 73.5972 18.709 74.2708 18.709Z"
                    fill="#6D6D6D"/>
                <path
                    d="M84.8745 18.709H85.8723C86.5209 18.709 86.8701 18.3847 86.8701 17.7112V7.23416C86.8701 6.58558 86.5209 6.23635 85.8723 6.23635H80.6837C79.9603 6.23635 79.611 6.56063 79.611 7.20921C79.5611 8.75583 79.5112 10.053 79.4364 11.1256C79.3616 12.2232 79.2618 13.0963 79.1371 13.7948C79.0123 14.4933 78.8627 15.0171 78.688 15.3414C78.5134 15.6906 78.3139 15.8653 78.1143 15.8902C77.5655 15.965 77.316 16.2893 77.316 16.888V17.7112C77.316 18.3598 77.5655 18.709 78.1143 18.709H78.3139C79.0622 18.6841 79.6859 18.4845 80.1848 18.0854C80.6837 17.7112 81.0828 17.1375 81.3821 16.3891C81.6815 15.6408 81.8811 14.7178 82.0307 13.5703C82.1804 12.4228 82.2802 11.0757 82.355 9.52913C82.355 9.32957 82.38 9.20484 82.4797 9.15495C82.5795 9.10506 82.7042 9.08012 82.9038 9.08012H83.3778C83.7021 9.08012 83.8767 9.25474 83.8767 9.57903V17.7112C83.8767 18.3847 84.201 18.709 84.8745 18.709Z"
                    fill="#6D6D6D"/>
                <path
                    d="M96.8683 14.593H95.8705C95.3966 14.593 95.1222 14.6928 95.0224 14.8425C94.9226 14.9922 94.8727 15.1668 94.8727 15.3414C94.8727 15.4412 94.8478 15.5659 94.8228 15.6657C94.7729 15.7904 94.723 15.8902 94.6233 15.99C94.5235 16.0898 94.3738 16.1646 94.1742 16.2145C93.9747 16.2644 93.7252 16.2893 93.4009 16.2644C93.1515 16.2644 92.902 16.2145 92.7025 16.1646C92.5029 16.1147 92.3283 15.99 92.1786 15.8403C92.0289 15.6906 91.9042 15.4911 91.8294 15.1917C91.7545 14.9173 91.7296 14.5432 91.7296 14.0692V13.695C91.7296 13.4206 91.8793 13.2709 92.2285 13.2709H96.9681C97.6167 13.2709 97.9659 12.9467 97.9659 12.2731V9.85343C97.9659 9.35452 97.8662 8.85561 97.6666 8.38165C97.467 7.90769 97.1677 7.48361 96.7686 7.13438C96.3694 6.78514 95.8955 6.51074 95.3467 6.31118C94.7729 6.11162 94.1244 5.98689 93.4009 5.98689C92.6027 5.98689 91.9292 6.11162 91.3554 6.36107C90.7567 6.61053 90.2828 6.93482 89.8836 7.33394C89.4845 7.73307 89.2101 8.20703 89.0106 8.75583C88.811 9.30463 88.7362 9.87837 88.7362 10.4771V14.4683C88.7362 15.067 88.8359 15.6408 89.0355 16.1896C89.2351 16.7383 89.5095 17.2373 89.9086 17.6364C90.2828 18.0355 90.7567 18.3598 91.3554 18.6093C91.9292 18.8587 92.6027 18.9585 93.376 18.9585C93.9996 18.9585 94.5983 18.8837 95.1471 18.709C95.6959 18.5594 96.1699 18.3099 96.569 18.0106C96.9681 17.7112 97.2675 17.362 97.5169 16.9629C97.7414 16.5637 97.8662 16.1147 97.8662 15.6158C97.8662 15.2666 97.7913 14.9922 97.6417 14.8425C97.492 14.6928 97.2176 14.593 96.8683 14.593ZM94.9725 10.1029V10.4771C94.9725 10.8263 94.7979 10.976 94.4736 10.976H92.2285C91.8793 10.976 91.7296 10.8263 91.7296 10.4771V10.1029C91.7296 9.7287 91.8543 9.40441 92.1537 9.13001C92.453 8.85561 92.8521 8.70594 93.4009 8.70594C93.8998 8.70594 94.274 8.85561 94.5484 9.13001C94.8228 9.40441 94.9725 9.7287 94.9725 10.1029Z"
                    fill="#6D6D6D"/>
                <path
                    d="M106.987 18.709H107.985C108.634 18.709 108.983 18.3847 108.983 17.7112V7.23416C108.983 6.58558 108.634 6.23635 107.985 6.23635H106.987C106.314 6.23635 105.989 6.58558 105.989 7.23416V10.5768C105.989 10.9261 105.815 11.0757 105.49 11.0757H103.245C102.896 11.0757 102.746 10.9261 102.746 10.5768V7.23416C102.746 6.58558 102.397 6.23635 101.749 6.23635H100.751C100.077 6.23635 99.753 6.58558 99.753 7.23416V17.7112C99.753 18.3847 100.077 18.709 100.751 18.709H101.749C102.397 18.709 102.746 18.3847 102.746 17.7112V14.3935C102.746 14.0692 102.896 13.8946 103.245 13.8946H105.49C105.815 13.8946 105.989 14.0692 105.989 14.3935V17.7112C105.989 18.3847 106.314 18.709 106.987 18.709Z"
                    fill="#6D6D6D"/>
                <path
                    d="M118.339 18.709H119.337C119.986 18.709 120.335 18.3847 120.335 17.7112V7.23416C120.335 6.58558 119.986 6.23635 119.337 6.23635H118.339C117.666 6.23635 117.341 6.58558 117.341 7.23416V10.5768C117.341 10.9261 117.167 11.0757 116.843 11.0757H114.597C114.248 11.0757 114.099 10.9261 114.099 10.5768V7.23416C114.099 6.58558 113.749 6.23635 113.101 6.23635H112.103C111.429 6.23635 111.105 6.58558 111.105 7.23416V17.7112C111.105 18.3847 111.429 18.709 112.103 18.709H113.101C113.749 18.709 114.099 18.3847 114.099 17.7112V14.3935C114.099 14.0692 114.248 13.8946 114.597 13.8946H116.843C117.167 13.8946 117.341 14.0692 117.341 14.3935V17.7112C117.341 18.3847 117.666 18.709 118.339 18.709Z"
                    fill="#6D6D6D"/>
                <path
                    d="M128.868 18.709H129.866C130.515 18.709 130.864 18.3847 130.864 17.7112V7.23416C130.864 6.58558 130.515 6.23635 129.866 6.23635H126.424C125.027 6.23635 123.904 6.61053 123.106 7.309C122.283 8.00747 121.883 8.98034 121.883 10.1777C121.883 11.2254 122.083 12.0237 122.532 12.6224C122.981 13.2211 123.58 13.6451 124.328 13.9195L122.083 17.8359C121.734 18.4346 121.908 18.709 122.582 18.709H123.655C124.303 18.709 124.802 18.4346 125.151 17.8359L127.247 13.72C127.396 13.3957 127.496 13.1213 127.521 12.8718C127.521 12.6224 127.496 12.4228 127.396 12.2482C127.297 12.0985 127.147 11.9738 126.947 11.8989C126.748 11.8241 126.548 11.7742 126.349 11.7742C125.8 11.7742 125.401 11.6495 125.201 11.425C124.977 11.2254 124.877 10.8512 124.877 10.3024C124.877 9.80353 124.952 9.4543 125.126 9.22979C125.276 9.00528 125.7 8.88055 126.349 8.88055H127.371C127.696 8.88055 127.87 9.05517 127.87 9.37946V17.7112C127.87 18.3847 128.195 18.709 128.868 18.709Z"
                    fill="#6D6D6D"/>
                <path
                    d="M132.707 22.4508H133.804C134.453 22.4508 134.902 22.1515 135.151 21.5029L142.71 0.947926C142.934 0.32429 142.71 0 142.061 0H140.964C140.29 0 139.841 0.32429 139.617 0.947926L132.058 21.5029C131.809 22.1515 132.033 22.4508 132.707 22.4508Z"
                    fill="#6D6D6D"/>
              </svg>
            </div>

            <a onclick="gtag('event', 'click', {'event_category': 'Фахівцям охорони здоров’я', 'event_label': 'меню'}); " @click="menu = false;"
               href="https://vakcynacia.com.ua/ua/doc-module" target="_blank" class="link4">Фахівцям охорони здоров’я
              <svg class="arrow" width="20" height="19" viewBox="0 0 20 19" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5 9.49998L0 9.5" stroke="#F36633" stroke-width="2"/>
                <path d="M10.6431 18.0714L18.5 9.5L10 1" stroke="#F36633" stroke-width="2"/>
              </svg>
            </a>
            <a onclick="gtag('event', 'click', {'event_category': 'Статтi', 'event_label': 'меню'});" @click="  menu = false;"
               href="https://vakcynacia.com.ua/ua/articles" target="_blank" class="link4">Статтi
              <svg class="arrow" width="20" height="19" viewBox="0 0 20 19" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5 9.49998L0 9.5" stroke="#F36633" stroke-width="2"/>
                <path d="M10.6431 18.0714L18.5 9.5L10 1" stroke="#F36633" stroke-width="2"/>
              </svg>
            </a>
            <a onclick="gtag('event', 'click', {'event_category': 'Відео', 'event_label': 'меню'});" @click="menu = false;"
               href="https://vakcynacia.com.ua/ua/videos" target="_blank" class="link4">Відео
              <svg class="arrow" width="20" height="19" viewBox="0 0 20 19" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5 9.49998L0 9.5" stroke="#F36633" stroke-width="2"/>
                <path d="M10.6431 18.0714L18.5 9.5L10 1" stroke="#F36633" stroke-width="2"/>
              </svg>
            </a>
            <a onclick="gtag('event', 'click', {'event_category': 'Календар щеплень', 'event_label': 'меню'});" @click=" menu = false;"
               href="https://vakcynacia.com.ua/ua/calendar" target="_blank" class="link4">Календар щеплень
              <svg class="arrow" width="20" height="19" viewBox="0 0 20 19" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5 9.49998L0 9.5" stroke="#F36633" stroke-width="2"/>
                <path d="M10.6431 18.0714L18.5 9.5L10 1" stroke="#F36633" stroke-width="2"/>
              </svg>
            </a>
            <a onclick="gtag('event', 'click', {'event_category': 'Вакцинація дорослих', 'event_label': 'меню'});" @click="  menu = false;"
               href="https://adult.vakcynacia.com.ua/#home" target="_blank" class="link4">Вакцинація дорослих
              <svg class="arrow" width="20" height="19" viewBox="0 0 20 19" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5 9.49998L0 9.5" stroke="#F36633" stroke-width="2"/>
                <path d="M10.6431 18.0714L18.5 9.5L10 1" stroke="#F36633" stroke-width="2"/>
              </svg>
            </a>
            <a onclick="gtag('event', 'click', {'event_category': 'Верифікація вакцин', 'event_label': 'меню'});" @click="  menu = false;"
               href="https://vakcynacia.com.ua/ua/medicine_verification" target="_blank" class="link4">Верифікація вакцин
              <svg class="arrow" width="20" height="19" viewBox="0 0 20 19" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5 9.49998L0 9.5" stroke="#F36633" stroke-width="2"/>
                <path d="M10.6431 18.0714L18.5 9.5L10 1" stroke="#F36633" stroke-width="2"/>
              </svg>
            </a>
            <a @click="menu = false;"
               onclick="gtag('event', 'click', {'event_category': 'Приватні центри вакцинації', 'event_label': 'меню'})"
               href="https://vakcynacia.com.ua/ua/available_vaccine" target="_blank" class="link4">Приватні центри
              вакцинації
              <svg class="arrow" width="20" height="19" viewBox="0 0 20 19" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5 9.49998L0 9.5" stroke="#F36633" stroke-width="2"/>
                <path d="M10.6431 18.0714L18.5 9.5L10 1" stroke="#F36633" stroke-width="2"/>
              </svg>
            </a>
            <span></span>
            <a onclick="gtag('event', 'click', {'event_category': 'Особистий кабінет', 'event_label': 'меню'});"
               @click="menu = false;"
               href="https://vakcynacia.com.ua/ua/cabinet" target="_blank" class="link4">Особистий кабінет
              <svg class="arrow" width="20" height="19" viewBox="0 0 20 19" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5 9.49998L0 9.5" stroke="#F36633" stroke-width="2"/>
                <path d="M10.6431 18.0714L18.5 9.5L10 1" stroke="#F36633" stroke-width="2"/>
              </svg>
            </a>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fixedBtn",
  data: () => ({
    menu: false,
    styleObjectFixBtn: {
      right: 0
    }
  }),
  mounted() {
    let p = document.querySelector(".section2");

    let style = p.currentStyle || window.getComputedStyle(p);

    this.styleObjectFixBtn.right = style.marginLeft

    addEventListener('resize', (event) => {
      let style = p.currentStyle || window.getComputedStyle(p);
      this.styleObjectFixBtn.right = style.marginLeft

      if (this.menu) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }

    });
  },

  methods: {
    scrollMeTo(refName) {
      let element = document.querySelector(refName);
      let top = element.offsetTop - 94;

      window.scrollTo({
        top: top,
        behavior: "smooth"
      });
    },
    getHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  },

}
</script>

<style scoped lang="scss">
@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.fixedBtn {
  position: fixed;
  top: 36px;
  padding-right: 40px;
  z-index: 100;

  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    padding-right: 20px;
  }

  @media screen and (max-width: 620px) {
    top: 20px;
    padding-left: 20px;
    align-items: center;
  }

  .mob-logo {
    display: none;
    @media screen and (max-width: 620px) {
      display: block;
      width: 103px;
      height: auto;
    }

  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #FFFFFF;
    /* #13575B */

    border: 1px solid #13575B;
    border-radius: 12px;
    padding: 15px 24px;

    cursor: pointer;
    transition: all .2s ease;

    @media screen and (max-width: 1024px) {
      padding: 10px 16px;
    }

    @media screen and (min-width: 1023px) {
      &:hover {
        background: #A6E7EB;
      }
    }

    &:active {
      background: #3E868A;

      p {
        color: white;
      }

      path {
        fill: white;
        stroke: white;
      }
    }

    p {
      color: #13575B;
      font-size: 16px;
      line-height: 20px;
      font-family: $medium;
      transition: all .2s ease;

      @media screen and (max-width: 620px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &.btn1 {
      margin-right: 12px;
      transition: all .2s ease;


      @media screen and (max-width: 1024px) {
        max-width: 165px;
      }

      @media screen and (max-width: 620px) {
        padding: 10px 15px;
        margin-right: 6px;
        margin-left: 12px;
        max-width: 150px;
      }

      @media screen and (max-width: 357px) {
        max-width: 125px;
        padding: 10px 10px;
      }

      @media screen and (max-width: 357px) {
        margin-right: 3px;
        margin-left: 6px;
      }

      p {
        @media screen and (max-width: 357px) {
          font-size: 12px;
        }
      }

      svg {
        max-width: 13px;
        min-width: 13px;
        width: 100%;
        height: auto;
      }
    }

    &.btn2 {
      margin-left: 12px;

      @media screen and (max-width: 620px) {
        border-radius: 50%;
        padding: 10px;
        width: 40px;
        height: 40px;
        margin-left: 6px;
      }

      @media screen and (max-width: 357px) {
        margin-left: 3px;
      }

      p {
        @media screen and (max-width: 620px) {
          display: none;
        }
      }

      svg {
        max-width: 20px;
        min-width: 20px;
        width: 100%;
        height: auto;
        @media screen and (max-width: 620px) {
          margin-right: 0;
        }
      }
    }

    svg {
      display: block;

      margin-right: 5px;

      path {
        transition: all .2s ease;
      }
    }
  }

  .menu-block {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-height: calc((var(--vh, 1vh) * 100));

    background: rgba(109, 159, 163, 0.8);
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity .2s;

    &.active {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;

      .white-bg {
        transform: translateX(0);
      }
    }

    .white-bg {
      transform: translateX(100%);
      max-width: 50%;
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      transition: transform .2s;

      padding: 32px 40px 32px 40px;

      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(62, 134, 138, 0.5);
      }

      @media screen and (max-width: 1180px) {
        max-width: 60%;
      }

      @media screen and (max-width: 1024px) {
        max-width: 100%;
        align-items: center;
        padding: 32px 40px 32px 40px;
      }

      @media screen and (max-width: 620px) {
        padding: 24px 20px 24px 20px;
      }

      .menu-content-wrapper {
        max-width: 483px;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1024px) {
          max-width: 576px;
        }

        .menu-title {
          font-size: 48px;
          line-height: 52px;
          color: #000000;

          font-family: $medium;

          @media screen and (max-width: 1024px) {
            margin-top: 48px;
          }

          @media screen and (max-width: 620px) {
            font-size: 32px;
            line-height: 120%;
          }
        }

        .close-btn {
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          @media screen and (min-width: 1023px) {
            &:hover {
              circle {
                fill: #A6E7EB;
              }
            }
          }


          &:active {
            circle {
              fill: #3E868A;
            }

            line {
              stroke: white;
            }
          }

          svg {
            display: block;
            width: 48px;
            height: auto;

            circle, line {
              transition: all .2s ease;
            }

            @media screen and (max-width: 1024px) {
              width: 64px;
            }

            @media screen and (max-width: 620px) {
              width: 40px;
            }
          }
        }

        .line1 {
          margin-top: 40px;

          @media screen and (max-width: 1024px) {
            max-width: 483px;
            margin-top: 35px;
          }

          .link1 {
            font-size: 32px;
            line-height: 140%;
            font-family: $reg;

            color: #F36633;

            cursor: pointer;
            position: relative;
            display: inline-block;

            transition: all .2s ease;
            @media screen and (max-width: 620px) {
              font-size: 20px;
            }

            @media screen and (min-width: 1023px) {
              &:hover {
                color: #F36633;

                &:before {
                  width: 10%;
                }
              }
            }

            &:active {
              color: #DE3B00;

              &:before {
                width: 10%;
              }
            }

            &:before {
              content: '';
              width: 100%;
              height: 1px;
              background: #F36633;
              position: absolute;
              transition: all .2s ease;
              bottom: 0;
              left: 0;
            }
          }

          .link2 {
            font-family: $reg;
            font-size: 16px;
            line-height: 140%;

            color: #000000;
            margin-top: 24px;
            padding-left: 25px;

            cursor: pointer;
            transition: all .2s ease;

            position: relative;
            display: flex;
            align-items: center;
            transform-origin: 12% center;

            .arrow {
              opacity: 0;
              width: 17px;
              height: auto;
              display: block;
              margin-left: 7px;
              transition: all .2s ease;
            }

            @media screen and (min-width: 1023px) {
              &:hover {
                color: #F36633;
                transform: scale(1.8);

                .arrow {
                  opacity: 1;
                }
              }
            }

            &:active {
              color: #F36633;
              transform: scale(1.2);
              text-decoration: underline;

              .arrow {
                opacity: 0;
              }
            }

            @media screen and (max-width: 620px) {
              font-size: 14px;
              padding-left: 0;
              margin-top: 8px;
            }
          }
        }

        .line2 {
          margin-top: 64px;
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 620px) {
            margin-top: 36px;
          }

          @media screen and (max-width: 1024px) {
            max-width: 483px;
          }

          .sep {
            display: flex;
            justify-content: flex-end;
            border-bottom: 1px dashed #6D6D6D;
            padding-bottom: 10px;
            margin-bottom: 20px;
          }

          .link3 {
            font-size: 32px;
            line-height: 140%;

            color: #F36633;
            font-family: $reg;
            text-decoration: none;
            margin-bottom: 24px;

            @media screen and (max-width: 620px) {
              font-size: 20px;
              margin-bottom: 15px;
            }
          }

          .link4 {
            padding-left: 25px;
            margin-bottom: 25px;

            font-size: 16px;
            line-height: 140%;

            color: #000000;

            font-family: $reg;
            text-decoration: none;
            display: flex;
            align-items: center;
            transition: all .2s ease;

            transform-origin: 12% center;

            .arrow {
              width: 17px;
              height: auto;
              display: block;
              margin-left: 7px;
              opacity: 0;
              transition: all .2s ease;
            }

            @media screen and (min-width: 1023px) {
              &:hover {
                color: #F36633;
                transform: scale(1.8);

                .arrow {
                  opacity: 1;
                }
              }
            }

            &:active {
              color: #F36633;
              text-decoration: underline;
              transform: scale(1.35);

              .arrow {
                opacity: 0;
              }
            }

            @media screen and (max-width: 620px) {
              padding-left: 0;
              font-size: 14px;
              margin-bottom: 15px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style>