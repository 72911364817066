<template>
  <div id="app" v-bind:class="[{ active: mobile }]">

    <router-view/>
  </div>
</template>

<script>
export default {

  data: () => ({
    mobile: false,
  }),

  methods: {
    updateVH() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },

  mounted() {
    this.updateVH();

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    addEventListener('resize', (event) => {
      if (this.mobile === false) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }
    });

    if (/windows phone/i.test(userAgent)) {
      this.mobile = true;
    }

    if (/android/i.test(userAgent)) {
      this.mobile = true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.mobile = true;
    }

    this.$nextTick(() => {
      let ua = navigator.userAgent.toLowerCase();
      let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);


      if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        this.mobile = true
      }
      if (navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2 &&
          /MacIntel/.test(navigator.platform)) {
        this.mobile = true
      }

      if (isAndroid || iOS === true) {
        this.mobile = true
      }
    })

  },
};
</script>

<style lang="scss">

@import "./assets/style/fonts.css";
@import "./assets/style/vars";


#app {

  &.active {
    * {
      -webkit-tap-highlight-color: rgba(255, 0, 0, 0) !important;
      outline: none !important;
      cursor: none !important;
    }
  }
}

</style>
